import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { LoadingProvider } from "./assets/loader";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

root.render(
  <LoadingProvider>
    <QueryClientProvider client={queryClient}>
    <SnackbarProvider
            preventDuplicate
            maxSnack={3}
            iconVariant={{
              success: <ErrorOutlineIcon />,
              error: <ErrorOutlineIcon />,
              warning: <WarningAmberIcon />,
              info: <TaskAltIcon />,
              default: <WifiOffIcon />
            }}
          >
      <App />
      </SnackbarProvider>
    </QueryClientProvider>
  </LoadingProvider>
);
