import React, { useEffect, useState } from "react";
import "./style.scss";
import { Box, Button, Typography } from "@mui/material";
import { DynamicInstructionsProps, IErrorResponse, ISessionDataV2 } from "../../interfaces/test";
import { useNavigate, useParams } from "react-router-dom";
import { TestSessionService } from "../../services/test-session";
import useSnackbar from "../../hooks/useSnackbar";

const DynamicInstructions: React.FC<DynamicInstructionsProps> = ({sessionData }) => {
  const [data, setData] = useState<ISessionDataV2 | undefined>(undefined);
  const { startTest } = TestSessionService();
  const {snackbar} = useSnackbar();
  const {id} = useParams();
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      if (data && !data.isStarted) {
        await startTest({ _id: id });
      }
      navigate(`/${id}/test`);
    } catch (err) {
      const error = err as IErrorResponse;
      snackbar(error.data.message, "error");
    }
  };  


  useEffect(() => {
    if (sessionData.isSuccess && sessionData.data?.data) {
      setData(sessionData.data.data);
    } 
  }, [sessionData.isSuccess, sessionData.data?.data, sessionData.isError]);
  const htmlContent = data && data._assessment && data._assessment._instruction && data._assessment._instruction.instructions || "";
  return (
    <>
    <div className="instruction-div">
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
    <div className="content-footer">
    <Box
      className="button-container"
    >
      <Button
      className={data && data.isStarted ? "resume-button" : "start-button"}
      variant={data && data.isStarted ? "outlined" : "contained"}
      color={data && data.isStarted ? "inherit" : "primary"}
      onClick={handleClick}
    >
      {data && data.isStarted ? "Resume Test" : "Start Test"}
    </Button>
    </Box>
    <Typography className="footer-text" variant="body2" align="center">
      {data && data?.isStarted ? (
        <>
          Click the Resume button to resume the <strong>Assessments</strong>
        </>
      ) : (
        <>
          Click the Start button to begin the <strong>Assessments</strong>
        </>
      )}
    </Typography>
  </div>
  </>

  );
};

export { DynamicInstructions };
