import React, { useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Card,
  CardContent,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import "./style.scss";
import { IErrorResponse, IQuestion } from "../../interfaces/test";
import useSnackbar from "../../hooks/useSnackbar";
import { TestSessionService } from "../../services/test-session";

interface QuestionCardProps {
  questionData: IQuestion;
  index: number;
  totalQuestion: number;
  moduleId: string | null;
  id: string | undefined;
  onChangeQuestions: (quesId: string, selectedOption: string, moduleId:string|null) => void;
}

interface iOption {
  option: string;
}

const QuestionFieldCard: React.FC<QuestionCardProps> = ({
  questionData,
  index,
  totalQuestion,
  moduleId,
  id,
  onChangeQuestions,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const { saveQuestions } = TestSessionService();
  const { snackbar } = useSnackbar();

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    onChangeQuestions( questionData._id, event.target.value, moduleId);
    handleSubmission(event.target.value);
  };

  const handleSubmission = async (selectedOption: string) => {
    const payload = {
      _question: questionData._id,
      selectedOption: selectedOption,
      _test: moduleId,
      _id: id,
    };
    try {
      await saveQuestions(payload);
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  return (
<Card className="question-card">
  <CardContent >
    <Typography variant="body2" color="textSecondary" className="question-number">
      Question {index + 1} of {totalQuestion}
    </Typography>
    <div
      className="question-text"
      dangerouslySetInnerHTML={{
        __html: questionData.question,
      }}
    />
    <Box my={3}>
      <RadioGroup value={selectedOption} onChange={handleOptionChange}>
        {questionData.options.map((optionObject: iOption, optionIndex: number) => (
          <div
            key={optionIndex}
            className="option-box"
            onClick={(event) => {
              event.preventDefault();
              handleOptionChange({
                target: { value: optionObject.option },
              } as React.ChangeEvent<HTMLInputElement>);
            }}       
            >
            <div className="option-row">
              <span className="option-label">{String.fromCharCode(65 + optionIndex)}.</span>
              <input type="text" className="option-text" value={optionObject.option} readOnly />
              <div className="radio-option">
                <FormControlLabel
                  value={optionObject.option}
                  control={
                    <Radio
                      checked={
                        optionObject.option === questionData.selectedOption ||
                        optionObject.option === selectedOption
                      }
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleRoundedIcon  />}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                      }}
                    />
                  }
                  label=""
                  className="form-control"
                />
              </div>
            </div>
          </div>
        ))}
      </RadioGroup>
    </Box>
  </CardContent>
</Card>
  );
};
export { QuestionFieldCard };