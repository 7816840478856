import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { useLottie } from "lottie-react";
import Error from "../../assets/lottie/lottie/error.json";
import { FC } from "react";
import "./style.scss";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Error,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
interface IProps {
    failure?: boolean
}
const PageNotFound : FC<IProps>= ({failure}) => {
    const error = useRouteError();
    const { View } = useLottie(defaultOptions);
    return (
      <div className="not-found-container" >
        <Container maxWidth="sm" className="page-not-found">
        <Box className="center" flexDirection='column'>
            {View}
            <Typography variant="h3">Oops!</Typography>
            {failure ?  <Typography variant="h6">Sorry, an unexpected error has occurred.</Typography>
             : 
            <Typography variant="h6">Sorry, The page you are looking for does not exist.</Typography>
          }
            <Typography variant="body1">
              <i>{(isRouteErrorResponse(error) && error?.statusText) || (isRouteErrorResponse(error) && error?.data )|| ""}</i>
            </Typography>
          </Box>
        </Container>
      </div>
      );
};

export default PageNotFound;
