import { Typography } from "@mui/material";
import "./style.scss";

const BackFromTechnical = () => 
(    <div className="parent-submission-container">
    <h6 className="module-title">{localStorage.getItem("technical_question")}</h6>
    <div className="pending-submission">
      <div className="content">
        <div className="icon-container">
          <img src="/warning.svg" alt="warning icon" className="warning-icon" />
        </div>
        <Typography variant="h5" className="title">
          Pending Submission
        </Typography>
        <Typography variant="body1" className="message">
        Don&apos;t forget to complete your test before submitting the assignment        </Typography>
        <Typography variant="body1" className="instruction">
          Please select the next question to continue
        </Typography>
      </div>
    </div>
    </div>);



export { BackFromTechnical };