import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Button, Chip } from "@mui/material";
import { timeDifference } from "../../utilities";
import "./style.scss";
import { useParams } from "react-router-dom";


interface TestModuleCardProps {
  title: string;
  onStart: (moduleId: string, title: string) => void;
  questions?:
    | undefined
    | { _id: string; question: string; options: { option: string }[]; selectedOption: string }[];
  active: string | boolean;
  index: number;
  moduleId: string;
  attemptedQues?: number;
  totalQuestion?: number;
}

const TestModuleCard: React.FC<TestModuleCardProps> = ({
  title,
  onStart,
  totalQuestion,
  questions,
  active,
  index,
  moduleId,
  attemptedQues,
}) => {
  const [timeToShow, setTimeToShow] = useState("");
  const { id } = useParams();
  
  const handleStart = () => {
    onStart(moduleId, title);
  };

  if(localStorage.getItem(`${id}_categoryId`) === moduleId) {
  const attemptedQuestionFromState =
    questions &&
    questions.filter((question) => question.selectedOption !== "").length;
  
  localStorage.setItem(`${id}_attempted_total_id_${moduleId}` , JSON.stringify(attemptedQuestionFromState));
  }

  useEffect(() => {
    const showTimeIntervalId = setInterval(() => {
      setTimeToShow(() => 
          timeDifference(
            localStorage.getItem(`${id}_startedTime_${moduleId}`) ?? String(new Date()),
          ) ?? ""
      );
    }, 1000);
    return () => {
      clearInterval(showTimeIntervalId);
    };
  }, [id, moduleId]);

  return (
    <div className="parent-container">
      <Card
        className= { active? "card card-selected" : "card card-not-selected"}
      >
        <CardContent className= "card-content">
          <div className="card-body">
            <div className="card-index">{index + 1}.</div>
            <div className="card-content">
              <div className="card-content-main">
                <div className="card-title">{title}</div>
                <div>  
                  {active ? (
                    <Button
                      variant="contained"
                      className= {timeToShow ? "disabled-button" : "start-button"}   
                      color="primary"
                      size="small"
                      disabled
                    >
                      {!localStorage.getItem(`${id}_isStarted_${moduleId}`) ?(timeToShow ?  "Edit" : "Start"): (timeToShow ?  "Edit" : "Start")}
                    </Button>
                  ) : !localStorage.getItem(`${id}_isStarted_${moduleId}`) ? (
                    <Button
                      className="start-button"
                      onClick={handleStart}
                    >
                      Start
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={timeToShow? "edit-button" : "start-button"}
                      size="small"
                      onClick={handleStart}
                    >
                   {timeToShow ?  "Edit" : "Start"}
                    </Button>
                  )}
                </div>
              </div>
              <div className="card-content-bottom">
              {attemptedQues && totalQuestion ?
              <>
                <Typography variant="body2" color="textSecondary">
                {`${localStorage.getItem(`${id}_attempted_total_id_${moduleId}`) || attemptedQues}/${totalQuestion}`}

                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {timeToShow}
                </Typography>
                </>
             : ""}
              </div>
              {questions && localStorage.getItem(`${id}_categoryId`) === moduleId && (
                <div className="button-container">
                  {questions.map((question, index) => (
                    <Chip
                      key={index}
                      label={index + 1}
                      variant="outlined"
                      className={question.selectedOption !== "" ? "chip-selected" : "chip"}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export { TestModuleCard };