import HttpService from "../http";
import { IQuestionResponse, IResponse, IResponseV2, IRunCodeResponse, ITabSwitchResponse, ITechnicalQuestionResponse, IsaveQuestions, IsubmitQuestions } from "../../interfaces/test";
const test = "config";

export const TestSessionService = () => {
  const { httpRequest } = HttpService();
  const getTestSession = async (search: object): Promise<IResponseV2> =>
   new Promise((resolve, reject) => {
      httpRequest<IResponseV2>("GET", `${test}/public/test-session`, {}, search)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  const getQuestions = async (search: object): Promise<IQuestionResponse> => new Promise((resolve, reject) => {
      httpRequest<IQuestionResponse>("GET", `${test}/public/psy-questions`, {}, search)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  const startTest = async (payload: object): Promise<IResponse> =>  new Promise((resolve, reject) => {
      httpRequest<IResponse>(
        "PUT",
        `${test}/public/test-session/start`,
        payload,
      )
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  const saveQuestions = async (payload: object): Promise<IsaveQuestions> =>
    new Promise((resolve, reject) => {
      httpRequest<IsaveQuestions>(
        "PUT",
        `${test}/public/update-psy-response`,
        payload,
        {},
        false
      )
        .then(resolve)
        .catch(reject);
    });


  const saveSubmit = async (payload: object): Promise<IsubmitQuestions> =>
    new Promise((resolve, reject) => {
      httpRequest<IsubmitQuestions>(
        "POST",
        `${test}/public/test-session/submit`,
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const updateTabSwitches = async (payload: object): Promise<ITabSwitchResponse> => 
     new Promise((resolve, reject) => {
      httpRequest<ITabSwitchResponse>(
        "PUT",
        `${test}/public/test-session/candidate-activity`,
        payload,
      )
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  const getCodingQuestion = async (search: object): Promise<ITechnicalQuestionResponse> =>  new Promise((resolve, reject) => {
      httpRequest<ITechnicalQuestionResponse>("GET", `${test}/public/coding-question`, {}, search)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  const runCodingQuestion = async (payload: object): Promise<IRunCodeResponse> => 
     new Promise((resolve, reject) => {
      httpRequest<IRunCodeResponse>("PUT", `${test}/public/run-code`, payload,{})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });


  const submitCodingQuestion = async (payload: object): Promise<IRunCodeResponse> => 
   new Promise((resolve, reject) => {
      httpRequest<IRunCodeResponse>("PUT", `${test}/public/submit-coding-response`, payload,{})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });


  const saveCodingQuestionResponse = async (payload: object): Promise<IRunCodeResponse> => 
   new Promise((resolve, reject) => {
      httpRequest<IRunCodeResponse>("PUT", `${test}/public/update-coding-response`, payload,{},false)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  return {
    getTestSession,
    getQuestions,
    saveQuestions,
    startTest,
    saveSubmit,
    updateTabSwitches,
    getCodingQuestion,
    runCodingQuestion,
    submitCodingQuestion,
    saveCodingQuestionResponse
  };
};