import React, { useEffect, useState } from "react";
import "./style.scss";
import { Typography, Box, Button, Container, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ModuleCard from "../../../components/welcome-cat-card";
import { numberToWord } from "../../../utilities";
import TestCompleted from "../../test-completed";
import { DynamicInstructionsProps, IErrorResponse, ISessionDataV2, ITest, TestQuestion } from "../../../interfaces/test";
import PageNotFound from "../../page-not-found";
import useSnackbar from "../../../hooks/useSnackbar";
import { TestSessionService } from "../../../services/test-session";

const WelcomeScreenV2: React.FC<DynamicInstructionsProps>  = ({sessionData}) => {

  const navigate = useNavigate();
  const { id } = useParams();
  const { startTest } = TestSessionService();
  const { snackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<ISessionDataV2 | undefined>(undefined);

  const handleClick = async () => {
    try {
      if (data && !data.isStarted) {
        await startTest({ _id: id });
      }
      navigate(`/${id}/test`);
    } catch (err) {
      const error = err as IErrorResponse;
      snackbar(error.data.message, "error");
    }
  };  
  
  useEffect(() => {
    if (sessionData.isSuccess && sessionData.data?.data) {
      setData(sessionData.data.data);
      setSuccess(true);
      setLoading(false);
    } else if (sessionData.isError) {
      setLoading(false);
    }
  }, [sessionData.isSuccess, sessionData.data?.data, sessionData.isError]);

  if (loading) return <></>;
  if (!success) return <PageNotFound failure={!success} />;

  return data && data.isSubmitted ? (
    <TestCompleted />
  ) : (
    <>
      <Container className="container">
        <Typography className="container-header" variant="h5" align="center">
          Welcome to the Test Assessments!
        </Typography>
        <Typography variant="body1" align="center" className="container-paragraph">
          Before you begin, please take a moment to familiarise yourself with the process. You will
          be completing {numberToWord(data?._assessment._tests?.length ?? 0)} modules, each containing questions
          from different categories.
        </Typography>
      </Container>
      <div className="scrollable-container">
        <Container className="container">
          <div className="test-module-header-container">
          <Typography variant="body1" align="center" fontWeight={600} className="test-module-header test-moduleI-header-border">
            MCQ Tests
          </Typography>
          </div>
          <Grid container spacing={2}>
            {/* Set very small spacing */}
            {((data && data._assessment && data._assessment._tests) || []).map((value: ITest, index) => (
              <>
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ModuleCard
                    name={value.name}
                    description={value.description}
                    indentation={index}
                    type="MCQ"
                  />
                </Grid>
              </>
            ))}
          </Grid>
          <div className="test-module-header-container">
            <Typography variant="body1" align="center" fontWeight={600} className="test-module-header test-module-header-border">
            {data && data._assessment && data._assessment._codingChallenges.length ? "Coding Challenges" : ""}
            </Typography>
          </div>
          <Grid container spacing={2} paddingTop={"10px"}>
            {/* Set very small spacing */}
            {((data && data._assessment && data._assessment._codingChallenges) || []).map((value: TestQuestion, index) => (
              <>
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ModuleCard
                    name={value.title}
                    description={value.description}
                    indentation={index}
                    type="Coding Challenge"
                  />
                </Grid>
              </>
            ))}
          </Grid>
        </Container>
      </div>
      <div className="content-footer">
        <Box
          className="button-container"
        >
         {data && data?.isStarted ? (
          <>
          <Button
          className={data && data.isStarted ? "resume-button" : "start-button"}
          variant={data && data.isStarted ? "outlined" : "contained"}
          color={data && data.isStarted ? "inherit" : "primary"}
          onClick={handleClick}
        >
          {data && data.isStarted ? "Resume Test" : "Start Test"}
        </Button>
          <Typography className="footer-text" variant="body2" align="center">
          {data && data?.isStarted ? (
            <>
              Click the Resume button to resume the <strong>Assessments</strong>
            </>
          ) : (
            <>
              Click the Start button to begin the <strong>Assessments</strong>
            </>
          )}
        </Typography>
        </>
          ) : (
            <>
            <Button className= "start-button"  variant="contained" color="primary" onClick={()=>navigate(`/${id}/instructions`)}>
              Instructions
            </Button>
             <Typography className="footer-text" variant="body2" align="center">
              Click the Instruction button to begin with the <strong>Instructions</strong>
             </Typography>
             </>
          )}  
        </Box>
      </div>
    </>
  );
};

export { WelcomeScreenV2 };