import React, { useEffect, useState } from "react";
import { Card, CardContent, Button, Typography } from "@mui/material";
import { timeDifference } from "../../utilities";
import "./style.scss";
import { useParams } from "react-router-dom";

interface TestModuleCardProps {
  title: string;
  onStart: (moduleId: string, title: string) => void;
  index: number;
  moduleId: string;
  attemptedQues?: number;
  active: string | boolean;
  totalQuestion?: number;                                  
  isStarted?: boolean;
  isSubmitted?: boolean
}

const CodingChallengeCard: React.FC<TestModuleCardProps> = ({
  title,
  onStart,
  active,
  index,
  moduleId,
  isStarted,
  isSubmitted
}) => {
  const [timeToShow, setTimeToShow] = useState("");
  const { id } = useParams();

  const handleStart = () => {
    onStart(moduleId,title);
  };

  useEffect(() => {
    const showTimeIntervalId = setInterval(() => {
      setTimeToShow(() =>
          timeDifference(
            localStorage.getItem(`${id}_startedTime_${moduleId}`) ?? String(new Date()),
          ) ?? ""
      );
    }, 1000);
    return () => {
      clearInterval(showTimeIntervalId);
    };
  }, [id, moduleId]);

  return (
    <div className="parent-coding-container">
      <Card
        className={active? "card card-selected":  "card card-not-selected"}
      >
        <CardContent>
          <div className="card-body">
            <div className="card-index">{index + 1}.</div>
            <div className="card-content">
              <div className="card-content-main">
                <div className="card-title">{title}</div>
                <div>  
                  {isSubmitted ? (
                    <Button
                      variant="contained"
                      className= {"disabled-button"}   
                      color="primary"
                      size="small"
                      disabled
                    >
                      Submitted
                    </Button>
                  ) : isStarted ? (
                    <Button
                      variant="contained"
                      className= {"start-button"}   
                      color="primary"
                      size="small"
                      onClick={handleStart}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className= {"start-button"}   
                      color="primary"
                      size="small"
                      onClick={handleStart}
                    >
                      Start
                    </Button>
                  )
                }
                </div>
              </div>
              <div className="card-content-bottom">
                <Typography variant="body2" color="textSecondary">
                  {timeToShow}
                </Typography>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export { CodingChallengeCard };