import React, { useState, useEffect, useCallback } from "react";
import { IErrorResponse } from "../../interfaces/test";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import { getMinutesAndSecondsDifference } from "../../utilities";
import { TestSessionService } from "../../services/test-session";

interface TimerProps {
  completedTime : string;
}


const Timer: React.FC<TimerProps> = ({ completedTime }) => {
  const { id } = useParams(); 
  const [autoSubmission, setautoSubmission] = useState(true);
  const navigate = useNavigate();
  const { saveSubmit } = TestSessionService();
  const minuteSecondDifference = getMinutesAndSecondsDifference(completedTime);

  const submitTest = useCallback(async () => {
    try {
      await saveSubmit({ _id: id });
      navigate(`/${id}/autocompleted`);
      localStorage.clear();
    } catch (error) {
      const err = error as IErrorResponse;
      console.log(err.data.message);
    }
  }, [id, navigate, saveSubmit]);

  if (minuteSecondDifference.minutes <= 0 && minuteSecondDifference.seconds <= 0 && autoSubmission) {
    submitTest();
    setautoSubmission(false);
  }

  const [hours, setHours] = useState(Math.floor(minuteSecondDifference.minutes / 60));
  const [minutes, setMinutes] = useState(minuteSecondDifference.minutes % 60);
  const [seconds, setSeconds] = useState(minuteSecondDifference.seconds >= 0 ? minuteSecondDifference.seconds : 0);


  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours > 0) {
            setHours((prevHours) => prevHours - 1);
            setMinutes(59);
            setSeconds(59);
          } else {
            submitTest();
            clearInterval(interval);
          }
        } else {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [hours, minutes, seconds, submitTest]);

  return (
    <div className="timer-div">
      <Typography variant="body2" className="time-remaining-text">Time Remaining</Typography>   
      <Typography>
        <strong>{hours.toString().padStart(2, "0")}: {" "} 
        {minutes.toString().padStart(2, "0")}: {" "} 
        {seconds.toString().padStart(2, "0")}</strong>
      </Typography>
    </div>
  );
};

export  {Timer};