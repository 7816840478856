import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { InstructionScreen } from "../instruction-screen";
import { useQuery } from "@tanstack/react-query";
import { QuestionContainer } from "../../components/question-container";
import { TestModuleCard } from "../../components/test-module-card";
import { IErrorResponse, ISessionDataV2 } from "../../interfaces/test";
import "./style.scss";
import TestCompleted from "../test-completed";
import PageNotFound from "../page-not-found";
import { useData } from "../../hooks/useContext";
import { TestSessionService } from "../../services/test-session";
import { CodingChallengeCard } from "../../components/coding-challange-card";
import WarningDialog from "../../components/warning-dialog";
import useSnackbar from "../../hooks/useSnackbar";
import { BackFromTechnical } from "../../components/back-from-technical-card";
import { TechnicalSubmitted } from "../../components/technical-test-submitted";


const ExamScreenV2: React.FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sessionData, setsessionData] = useState<ISessionDataV2 | undefined>(undefined);
  const { questionsData, setQuestionsData } = useData();
  const [module, setModule] = useState({
    moduleId: localStorage.getItem(`${id}_categoryId`),
    moduleName: localStorage.getItem(`${id}_categoryName`),
  });
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { saveSubmit } = TestSessionService();
  const submitTest = async () => {
    try {
      await saveSubmit({ _id: id });
      navigate(`/${id}/completed`);
      localStorage.clear();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const handleStart = (moduleId: string, moduleName: string) => {
    const localStorageModuleId = moduleId
      ? moduleId
      : JSON.stringify(localStorage.getItem(`${id}_categoryId`));
    const localStorageModuleName = moduleName
      ? moduleName
      : JSON.stringify(localStorage.getItem(`${id}_categoryName`));

    setModule((prev) => ({
      ...prev,
      moduleId: localStorageModuleId,
      moduleName: localStorageModuleName,
    }));
    localStorage.setItem("TechnicalCard", "true");
    localStorage.setItem(`${id}_categoryId`, moduleId);
    localStorage.setItem(`${id}_categoryName`, moduleName);
    localStorage.setItem(`${id}_isStarted_${moduleId}`, "Module is Started");
  };

  const handleCoingChallengeStart = (moduleId: string, moduleName: string) => {
    const localStorageModuleId = moduleId
      ? moduleId
      : JSON.stringify(localStorage.getItem(`${id}_categoryId`));
    const localStorageModuleName = moduleName
      ? moduleName
      : JSON.stringify(localStorage.getItem(`${id}_categoryName`));

    setModule((prev) => ({
      ...prev,
      moduleId: localStorageModuleId,
      moduleName: localStorageModuleName,
    }));

    localStorage.setItem(`${id}_categoryId`, moduleId);
    localStorage.setItem(`${id}_categoryName`, moduleName);
    localStorage.setItem(`${id}_isStarted_${moduleId}`, "Module is Started");

    navigate(`/${id}/test/${moduleId}`);
  };

  const { getTestSession, getQuestions, updateTabSwitches } = TestSessionService();
  const sessionDataRaw = useQuery({
    queryKey: ["getTest", id],
    queryFn: () =>
      getTestSession({
        _id: id
      })
  });
  const questionsDataRaw = useQuery({
    queryKey: ["getQuestions", id, module.moduleId],
    queryFn: () =>
      getQuestions({
        _id: id,
        _test: module.moduleId,
      }),
    enabled: !!module.moduleId
  });

  const openDialog = () => { setDialogStatus((prev)=>!prev); };

  useEffect(() => {
    if (sessionDataRaw.isSuccess && sessionDataRaw.data?.data) {
      (sessionDataRaw.data.data._assessment._tests || []).map((data) => {
        localStorage.setItem(
          `${id}_attempted_total_id_${data._id}`,
          String(data.numberOfAttemptedQuestions),
        );
        return undefined;
      });
      setsessionData(sessionDataRaw.data.data);
      setSuccess(true);
      setLoading(false);
    } else if (sessionDataRaw.isError) {
      setLoading(false);
    }
  }, [sessionDataRaw.isSuccess, sessionDataRaw.data?.data, sessionDataRaw.isError, id]);

  useEffect(() => {
    if (questionsDataRaw.isSuccess && questionsDataRaw.data?.data) {
      setQuestionsData(questionsDataRaw.data?.data.questions);
      setSuccess(true);
      setLoading(false);
    } else if (questionsDataRaw.isError) {
      setLoading(false);
    }
  }, [
    questionsDataRaw.isSuccess,
    setQuestionsData,
    questionsDataRaw.data?.data,
    questionsDataRaw.isError,
  ]);

  useEffect(() => {
    const updateTabSwitchesLatest = updateTabSwitches;

    const handleVisibilityChange = async () => {
      if (document.hidden) {
        try {
          await updateTabSwitchesLatest({
            _id: id,
            tabSwitchCount: 1,
          });
        } catch (error) {
          console.log(error);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [id, updateTabSwitches]);

  if (loading) return <></>;
  if (!success) return <PageNotFound failure={!success} />;

  return (
  sessionData && sessionData.isSubmitted ? (
    <TestCompleted />
  ) : (
    <>
     <WarningDialog 
        isOpen = {dialogStatus} 
        onClose = {openDialog}
        onConfirm = { () => submitTest() }
        title="Submit The Test"
        description="Are you sure you want to Submit the Question?"
      />
      <Grid container spacing={3} className="exam-screen-container">
        <Grid item xs={12} md={4} className="module-container-div"  >
          <Box>
            <Typography variant="h6" fontWeight={600} mb={2}>
              Modules
            </Typography>
            <div className="module-container">
            <Typography variant="body2" className="module-heading" fontWeight={600} mb={2}>
              MCQ Tests
            </Typography>
            <div>  
              {sessionData &&
                (sessionData._assessment._tests || []).map((test, index) => (
                  <TestModuleCard
                    key={test._id}
                    title={test.name}
                    onStart={handleStart}
                    questions={questionsData}
                    active={localStorage.getItem(`${id}_categoryId`) === test._id}
                    moduleId={test._id}
                    attemptedQues={test.numberOfAttemptedQuestions}
                    totalQuestion={test.numberOfQuestions}
                    index={index}
                  />
                ))}
            </div>
                 <Typography variant="body2" className="module-heading" fontWeight={600} mb={2}>
                 {sessionData && 
                 sessionData._assessment && 
                 sessionData._assessment._codingChallenges && 
                 sessionData._assessment._codingChallenges.length

                 ? "Coding Challenges" : ""}
            </Typography>
              {sessionData &&
                (sessionData._assessment._codingChallenges || []).map((test, index) => (
                  <CodingChallengeCard
                    key={test._id}
                    title={test.title}
                    onStart={handleCoingChallengeStart}
                    isSubmitted={
                      sessionData &&
                      sessionData.challengesResponse &&
                      sessionData.challengesResponse.find((data) => data._question._id === test._id)?.isSubmitted}
                    isStarted={
                      sessionData &&
                      sessionData.challengesResponse &&
                      sessionData.challengesResponse.find((data) => data._question._id === test._id)?.isStarted}
                    active={localStorage.getItem(`${id}_categoryId`) === test._id}
                    moduleId={test._id}
                    index={index}
                  />
                ))}
            </div>
          </Box>
        </Grid>

        <Grid className="class-container" item xs={12} md={8}>
        {!localStorage.getItem(`${id}_categoryId`) ? (
        <InstructionScreen />)
        : ( localStorage.getItem("TechnicalCard") === "submitted" ? (
          <TechnicalSubmitted />
        ) : localStorage.getItem("TechnicalCard") === "false" ? (
          <BackFromTechnical />
        ) : module && questionsData ? (
          <QuestionContainer module={module} sessionId={id} />
        ) : null
      )}
      </Grid>

      </Grid>
    </>
  ));
};

export { ExamScreenV2 };