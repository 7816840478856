import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "./style.scss";
interface Module {
  name: string;
  description: string;
  indentation: number;
  type: string
}

const ModuleCard: React.FC<Module> = ({ name, description, indentation,type }) => 
    <Card
    className="test-card"
    >
      <div
      className="test-card-box"
      >
        <Typography
          variant="body1"
          className="test-card-number"
          align="center"
        >
          {indentation + 1}
        </Typography>
      </div>
      <img
        className="test-card-icon"
        src={type === "MCQ" ? "/test-icon.svg" : "/coding-icon.svg"}
        alt="Start icon"
      />

      <CardContent className="card-content">
        <Typography
        className="test-card-title"
          variant="h5"
          align="center"
        >
          {name}
          </Typography>
        <Typography
          variant="body1"
          align="center"
          className="test-card-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </CardContent>
    </Card>;

export default ModuleCard;
