// DataContext.tsx
import React, { createContext, useContext, useState } from "react";
import { IQuestion } from "../interfaces/test";

interface DataContextType {
  questionsData: IQuestion[] | undefined;
  setQuestionsData: React.Dispatch<React.SetStateAction<IQuestion[] | undefined>>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [questionsData, setQuestionsData] = useState<IQuestion[] | undefined>(undefined);

  return (
    <DataContext.Provider value={{ questionsData, setQuestionsData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
