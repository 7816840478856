function timeDifference(date: string): string | null {
  const now = new Date();
  const then = new Date(date);

  const timeDiff = Math.abs(now.getTime() - then.getTime());
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  if (!seconds && !minutes && !hours) {
    return "";
  } 
  else if (seconds < 60) {
    return "Saved Just now";

  } 
  else if (minutes < 60) {
    return `Saved ${minutes} minutes ago`;
  } else {
    return `Saved ${hours} hours ago`;
  }
}
export {timeDifference};

export const numberToWord= (number: number) =>  {
  const ones = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  const teens = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
  const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

  if (number === 0) return "zero";

  let word = "";

  if (number >= 100) {
      word += ones[Math.floor(number / 100)] + " hundred ";
      number %= 100;
  }

  if (number >= 20) {
      word += tens[Math.floor(number / 10)] + " ";
      number %= 10;
  } else if (number >= 11 && number <= 19) {
      return teens[number - 10];
  }

  word += ones[number];

  return word.trim();
};
export const capitalizeFirstLetter = (str : string|null)=> typeof(str) === "string" 
  ? str.charAt(0).toUpperCase() + str.slice(1) : 
  "";

export const getMinutesAndSecondsDifference = (date1: string): { minutes: number; seconds: number } => {
  const startTime = new Date();
  const completedTime = new Date(date1);
  const differenceInMilliseconds = completedTime.getTime() - startTime.getTime();
  const minutes = Math.floor(differenceInMilliseconds / (1000 * 60));
  const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);
  return { minutes, seconds };
};