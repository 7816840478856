import React, { useEffect, useRef, useState } from "react";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { Box, Button, Typography } from "@mui/material";
import Editor from "@monaco-editor/react";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
import LanguageSelector from "../../components/language-selector";
import { Chip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { TestSessionService } from "../../services/test-session";
import { IErrorResponse, ILanguage, TestCaseOutput, TestQuestion } from "../../interfaces/test";
import useDebounce from "../../hooks/useDebounce";
import WarningDialog from "../../components/warning-dialog";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import useSnackbar from "../../hooks/useSnackbar";
import { editor as MonacoEditor } from "monaco-editor";
import PageNotFound from "../page-not-found";


const TechnicalTestQuestionScreen: React.FC = () => {
  const { id, questionId } = useParams();
  const navigate = useNavigate();
  const { saveCodingQuestionResponse, runCodingQuestion, submitCodingQuestion, getTestSession } = TestSessionService();
  const [question, setQuestion] = useState<TestQuestion>({} as TestQuestion);
  const [language, setLanguage] = useState<ILanguage>({} as ILanguage);
  const [editorValue, setEditorValue] = useState<string>("");
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [testCaseResponse, setTestCaseResponse] = useState<TestCaseOutput[]>([]);
  const [darkMode, setDarkMode] = useState<boolean>(true);
  const [submitWarning, setSubmitWarning] = useState<boolean>(false);
  const [resetWarning, setResetWarning] = useState<boolean>(false);
  localStorage.setItem("technical_question", question.title);

  const [duration, setDuration] = useState<number>(0);

  const editorRef = useRef<MonacoEditor.IStandaloneCodeEditor | null>(null);

  const testSession = useQuery({
    queryKey: ["getTestSubmited", questionId],
    queryFn: () =>
      getTestSession({
        _id: id
      })
  });
  const searchRecord = useDebounce(editorValue, 1000);

  const { snackbar } = useSnackbar();

  useEffect(() => {
    const payload = {
      languageId: language._id,
      questionId: questionId,
      _id: id,
      code: searchRecord,
    };
    const saveRecord = async () => {
      if (id === "new") return;
      if (!initialRender) {
        await saveCodingQuestionResponse(payload);
      }
      const tempDate = new Date();  
      localStorage.setItem(`${id}_startedTime_${questionId}`, String(tempDate));
    };

    saveRecord();
  }, [searchRecord]);


  useEffect(() => {
    if (testSession.isSuccess && testSession.data?.data) {
      const sessionData = testSession.data.data;
      setDuration(sessionData.duration);
      const questionData = sessionData._assessment._codingChallenges.find((question) => question._id === questionId);
      if (questionData) {
        setQuestion(questionData);

        const response = sessionData.challengesResponse.find((response) => response._question._id === questionId);
        let initialLanguage: ILanguage;
        if (response) {
          initialLanguage = questionData.languages.find((lang) => lang._id === response.languageSelected) || questionData.languages[0];
        } else {
          initialLanguage = questionData.languages[0];
        }

        setLanguage(initialLanguage);

        const savedCode = response ? response.code : null;
        const initialTemplate = questionData.templates.find(template => template.language._id === initialLanguage._id);
        const editorValue = savedCode || (initialTemplate ? initialTemplate.content : "");
        setEditorValue(editorValue);
      }
    }
  }, [testSession.isSuccess, testSession.data?.data, testSession.isError, questionId]);


  useEffect(() => {
    setInitialRender(false);
  }, [initialRender]);

  const dateFromApiFormatted = new Date(testSession.data?.data.startedDate ? testSession.data?.data.startedDate : "");

  if (duration) {
    dateFromApiFormatted.setMinutes(dateFromApiFormatted.getMinutes() + duration);
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSelect = (language: ILanguage) => {
    setLanguage(language);

    if (question && question.templates) {
      const selectedTemplate = question.templates.find((template) => template.language._id === language._id);
      const editorValue = selectedTemplate ? selectedTemplate.content : "";
      setEditorValue(editorValue);
    } else {
      setEditorValue("");
    }
  };

  const onMount = (editor: MonacoEditor.IStandaloneCodeEditor) => {
    editorRef.current = editor;
    editor.focus();
};

  const runCode = async () => {
    const code = editorRef.current && editorRef.current.getValue();
    if (!code) return;
    const payload = {
      languageId: language._id,
      questionId: questionId,
      _id: id,
      code,
    };
    try {
      const response = await runCodingQuestion(payload);
      setTestCaseResponse(response.data);
    } catch (error) {
      const err = error as Error;
      console.log(err.message);
    }
  };

  const submitCode = async () => {
    const code = editorRef.current && editorRef.current.getValue();
    if (!code) return;
    const payload = {
      languageId: language._id,
      questionId: questionId,
      _id: id,
      code,
    };
    try {
      const response = await submitCodingQuestion(payload);
      const tempDate = new Date();
      setTestCaseResponse(response.data);
      snackbar(response.message,"info");
      localStorage.setItem("TechnicalCard", "submitted");
      localStorage.setItem(`${id}_startedTime_${questionId}`, String(tempDate));
      navigate(`/${id}/test`);
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message,"warning");
    }
  };

const onReset = () => {
    const selectedTemplate = question.templates.find(template => template.language._id === language._id);
    setEditorValue(selectedTemplate?.content ?? "");
    setResetWarning(false);
  };
  return (
    testSession.data?.data?.isSubmitted ? <PageNotFound failure={false} /> :
    <>
    <Box display="flex" className= "technical-main-screen">
      <ResizableBox
        className="technical-test-screen question-box"
        width={windowWidth / 3}
        height={Infinity}
        minConstraints={[windowWidth / 4, Infinity]}
        maxConstraints={[windowWidth / 3, Infinity]}
        axis="x"
        handle={<span className="custom-handle" onClick={(e) => e.stopPropagation()} />}
        resizeHandles={["e"]}
      >
        <Box height="100%" padding={2} overflow={"auto"}>

          <div className="question-chip-box">
          <Typography variant="body1" fontWeight={600} className="question-title">{question.title}</Typography>
            <Chip label={question.difficulty} variant="outlined" className={
                question.difficulty === "EASY" 
                  ? "question-chip-easy" 
                  : question.difficulty === "MEDIUM" 
                  ? "question-chip-moderate": "question-chip-hard"}  />
          </div>
          <div className="question-data">
            <Typography variant="body1" fontWeight={600}>{question.title}</Typography>
            <Typography variant="body2" marginTop={2} dangerouslySetInnerHTML={{ __html: question.description }} />
            <div className="test-cases">
              <Typography variant="body1" fontWeight={600} marginTop={2}>Examples</Typography>
              {question && question.testCases && question.testCases.map((testCase, index) => (
                <div key={index} className="test-cases-box">
                  <Typography variant="body2"><strong>Input:</strong> {testCase.input}</Typography>
                  <Typography variant="body2"><strong>Output:</strong> {testCase.output}</Typography>
                </div>
              ))}
              
            </div>
          </div>
        </Box>
      </ResizableBox>
      <ResizableBox
        className="technical-test-screen"
        width={windowWidth / 3}
        height={Infinity}
        minConstraints={[windowWidth / 4, Infinity]}
        maxConstraints={[windowWidth / 2, Infinity]}
        axis="x"
        handle={<span className="custom-handle" onClick={(e) => e.stopPropagation()} />}
        resizeHandles={["e"]}
      >
        <Box height="100%" padding={2} overflow={"hidden"}>
          <div className="question-language-container">
            <LanguageSelector languages={(question && question.languages) ?? []} language={language} onSelect={onSelect} />
            <div className="mode-icon" onClick={() => setDarkMode(!darkMode)}>
              {darkMode ? <DarkModeIcon /> : <DarkModeOutlinedIcon />}
            </div>
            <div className="reset-icon" 
            onClick={()=>setResetWarning(true)}
          >
              <RestartAltIcon />
            </div>

          </div>
          <Editor
            height="86vh"
            language={language && language.language}
            value={editorValue}
            theme={darkMode ? "vs-dark" : "vs"}
            options={{
              wordWrap: "on",
              minimap: { enabled: false },
              lineNumbersMinChars: 3,
              folding: false,
              fontSize: 16,
              automaticLayout: true,
              scrollbar: { vertical: "auto", horizontal: "auto" },
            }}
            onMount={onMount}
            onChange={(value) => {
              if (value) {
                setEditorValue(value);
              }
            }
            }
          />
        </Box>
      </ResizableBox>
      <Box className="technical-test-screen" flex={1} height="100%">
        <Box padding={2} overflow={"hidden"}>
          <div className="code-buttons">
            <Button
              variant="outlined"
              color="primary"
              className="buttons-run-code"
              onClick={runCode}
              startIcon={<PlayArrowIcon />}
            >
              Run Code
            </Button>
            <Button variant="contained" color="success" className="buttons-submit-questions" onClick={()=>setSubmitWarning(true)} >Submit Question</Button>
          </div>
          <Box
            className="result-box"
            padding={2}
            borderRadius={2}
            border="1px solid #ddd"
            height={"70vh"}
            overflow={"auto"}
          >
            {testCaseResponse && testCaseResponse.map((testCase, index) => (
              <Box
                key={index}
                marginBottom={2}
                padding={2}
                border="1px solid #ccc"
                borderRadius={2}
              >
                <Typography variant="body2">
                  <strong>Input:</strong> {testCase.testCase.input}
                </Typography>
                <Typography variant="body2">
                  <strong>Expected Output:</strong> {testCase.testCase.expectedOutput}
                </Typography>
                {testCase.error ? (
                  <Typography variant="body2" color="error">
                    <strong>Error:</strong> {testCase.error}
                  </Typography>
                ) : (
                  <>
                    <Typography variant="body2">
                      <strong>Output:</strong> {testCase.output}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Result:</strong> {testCase.evaluation.message}
                    </Typography>
                  </>
                )}
                <Typography variant="body2" style={{ wordBreak: "break-word" }}>
                  <strong>Status:</strong> {testCase.error ? "Failed" : (testCase.evaluation.success ? "Passed" : "Failed")}
                </Typography>
              </Box>

            ))}

          </Box>
        </Box>
        <WarningDialog
          isOpen={submitWarning}
          onClose={() => setSubmitWarning(false)}
          onConfirm={submitCode}
          title="Submit Question"
          description="Are you sure you want to Submit the Question?"
        />
        <WarningDialog
          isOpen={resetWarning}
          onClose={() => setResetWarning(false)}
          onConfirm={onReset}
          title="Reset Template"
          description="Are you sure you want to Reset the template?"
        />
      </Box>
    </Box>
    </>
  );
};

export { TechnicalTestQuestionScreen };
