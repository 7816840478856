import React, { useState } from "react";
import "./navbar.scss";
import { AppBar, Toolbar, Grid, Divider, IconButton, Box, Typography, capitalize, Button } from "@mui/material";
import { Timer } from "../timer";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { TestSessionService } from "../../services/test-session";
import { useQuery } from "@tanstack/react-query";
import { IErrorResponse } from "../../interfaces/test";
import WarningDialog from "../warning-dialog";
import useSnackbar from "../../hooks/useSnackbar";

  const CustomNavBar = () => {
    const { id } = useParams();
    const location = useLocation();
    const { questionId } = useParams();
    const isTestPresent = location.pathname.includes("/test");
    const { saveSubmit } = TestSessionService();
    const { snackbar } = useSnackbar();
    const [dialogStatus, setDialogStatus] = useState(false);
    const navigate = useNavigate();
    const openSubmitDialog = () => {
      openDialog();
  };
  const openDialog = () => { setDialogStatus((prev)=>!prev); };
  const submitTest = async () => {
    try {
      await saveSubmit({ _id: id });
      navigate(`/${id}/completed`);
      localStorage.clear();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const codingBackButton = () => {
    localStorage.setItem("TechnicalCard", "false");
    navigate(`/${id}/test`);
    };
  const { getTestSession } = TestSessionService();

  const sessionDataRaw = useQuery({
    queryKey: ["getTest", id],
    queryFn: () => getTestSession({ _id: id }),
  }); 
  const sessionData =  sessionDataRaw && sessionDataRaw.data && sessionDataRaw.data.data;
  const startDate= sessionData && sessionData.startedDate;
  const duration=sessionData && sessionData.duration;
  const dateFromApiFormatted = new Date(startDate? startDate : "");
  duration && dateFromApiFormatted && dateFromApiFormatted.setMinutes(dateFromApiFormatted.getMinutes() + duration);  
  let content;
  if(id && !isTestPresent){
    content = (              
      <Box display="flex" alignItems="center" className="user-container">
      <Box className="user-info">
        <Typography variant="h6" className="user-name">
          {capitalize(sessionData?.lead.name ?? "")}
        </Typography>
        <Typography variant="body2" color="textSecondary" className="lead-email">
          {sessionData?.lead.email ?? ""}
        </Typography>
      </Box>
      <img
        className="user-icon"
        src={"/user-icon.svg"}
        alt="Logo"
      />
    </Box>);
  } else {
    content = (
      <>
      <Button variant="contained" color="primary" onClick={openSubmitDialog} fullWidth className="nav-button">
      Submit Assessment
    </Button>
    </>
    );
  }

  return (
    <div>
         <WarningDialog 
        isOpen = {dialogStatus} 
        onClose = {openDialog}
        onConfirm = { () => submitTest() }
        title="Are you sure you want to Submit the test?"
        description="Once submitted, you won't be able to make any changes. Review you answers before proceeding"
      />
    <AppBar position="static" className="navbar">
      <Toolbar className="toolbar">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item display={"flex"} alignItems={"center"}>
            { questionId && <img src="/back-arrow.svg" alt="Back icon" height={30} className="back-button"  
            onClick={codingBackButton} />}
            <img
            className="nav-logo"
              src={"/logo.svg"}
              alt="Logo"
            />{" "}
            <div className = "timer-combined">
            { 
            isTestPresent &&
            <IconButton
            className="icon-button"
            >
              <QueryBuilderIcon className="clock" />
            </IconButton>
            }
            <Grid mx={2}>
           {startDate && duration &&  <Timer completedTime = {dateFromApiFormatted.toUTCString()}/>} 
            </Grid>
           </div>
         
          </Grid>
          <Grid item className="right-side-item">
            {content}
          </Grid>
        </Grid>
      </Toolbar>
      <Divider /> 
    </AppBar>
    <Outlet />
    </div>
  );
};

export  {CustomNavBar};