import React, { FC, MouseEvent } from "react";
import { Button, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./index.scss";

interface Props {
  isOpen: boolean;
  title: string;
  description: string;
  onClose: (e: MouseEvent<HTMLElement>) => void;
  onConfirm: (e: MouseEvent<HTMLElement>) => void;
}

const WarningDialog: FC<Props> = ({ isOpen, title, description, onClose, onConfirm }) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    aria-labelledby="customized-dialog-title"
    className="bootstrap-dialog"
  >
    <IconButton
      aria-label="close"
      onClick={onClose}
      className="icon-button-cross"
    >
      <CloseIcon />
    </IconButton>
    <DialogContent dividers className="dialog-content">
      <img src="/dialogBox.svg" alt="Start icon" className="img-icon" />
      <Typography variant="h4" className="typography-main">
        {title}
      </Typography>
      <Typography className="typography-sub" gutterBottom>
        {description}
      </Typography>
      <div className="button-container">
        <Button variant="contained" onClick={onConfirm} className="submit-button">
          Confirm
        </Button>
        <Button
          variant="contained"
          onClick={onClose}
          className="cancel-button"
        >
          Cancel
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

export default WarningDialog;
