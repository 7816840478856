import React, { useEffect, useRef } from "react";
import "./style.scss";
import { Typography, styled, Box } from "@mui/material";
import { QuestionFieldCard } from "../ques-field-card";
import { IQuestion } from "../../interfaces/test";
import { useData } from "../../hooks/useContext";
import DoneIcon from "@mui/icons-material/Done";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utilities";
interface Imodule {
  moduleId: string | null;
  moduleName: string | null;
}

interface IQuesContainerProp {
  module: Imodule;
  sessionId: string | undefined;
}

const QuestionContainer: React.FC<IQuesContainerProp> = ({ module, sessionId }) => {
  const { id } = useParams();
  const StyledDialogBox = styled(Box)``;

  const DialogContent = styled(Box)``;

  const { questionsData, setQuestionsData } = useData();

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef<number>(0);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [questionsData]);

  // Reset scroll position when module changes and update previousModuleRef
  const previousModuleRef = useRef<string | null>(null);
  useEffect(() => {
    if (containerRef.current && previousModuleRef.current !== module.moduleId) {
      containerRef.current.scrollTop = 0;
      scrollPositionRef.current = 0; // Reset the scroll position reference
      previousModuleRef.current = module.moduleId;
    }
  }, [module]);

  const changeAttemptedOption = (
    quesId: string,
    selectedOption: string,
    moduleId: string | null,
  ) => {
    if (questionsData) {
      setQuestionsData(
        questionsData?.map((data) => {
          if (data._id === quesId) {
            return { ...data, selectedOption: selectedOption };
          }
          return data;
        }),
      );

      const updatdAttempted =
        questionsData && questionsData.filter((data) => data.selectedOption !== "").length;
      const date = new Date();
      const tempDate = new Date();
      tempDate.setSeconds(date.getSeconds() - 1);
      localStorage.setItem(`${id}_startedTime_${moduleId}`, String(tempDate));
      localStorage.setItem(`${id}_attempted_total_id_${moduleId}`, String(updatdAttempted));
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (containerRef.current) {
      scrollPositionRef.current = containerRef.current.scrollTop;
    }
  };

  const attemptedQuestion =
    questionsData && questionsData.filter((data) => !!data.selectedOption).length;
  return (
    <div style={{padding: "0px"}}>
      <Typography variant="h6" fontWeight={600} mb={2}>
        {module.moduleName && capitalizeFirstLetter(module.moduleName.trim())}
      </Typography>
      <div className="question-module">
        <StyledDialogBox className="styled-dialog-box">
          <DialogContent
            className="dialog-content"
            ref={containerRef}
            onScroll={(e) => handleScroll(e)}
          >
            {(questionsData || []).map((question: IQuestion, index: number) => (
              <QuestionFieldCard
                key={index}
                questionData={question}
                index={index}
                totalQuestion={(questionsData || []).length}
                moduleId={module.moduleId}
                id={sessionId}
                onChangeQuestions={(
                  quesId: string,
                  selectedOption: string,
                  moduleId: string | null,
                ) => {
                  changeAttemptedOption(quesId, selectedOption, moduleId);
                }}
              />
            ))}
          </DialogContent>
          <Typography variant="body2" className="attempted-ques-status">
            <span
              className={
                questionsData &&
                (questionsData.length === attemptedQuestion
                  ? "green-color-text"
                  : (attemptedQuestion || 0) > 0
                    ? "yellow-color-text"
                    : "red-color-text")
              }
            >
              {(questionsData || []).length === attemptedQuestion ? (
                <DoneIcon className="done-icon" />
              ) : (
                " "
              )}
              {`${attemptedQuestion}/${(questionsData || []).length} is done`}
            </span>
          </Typography>
        </StyledDialogBox>
      </div>
    </div>
  );
};
export { QuestionContainer };