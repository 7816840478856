import { createBrowserRouter } from "react-router-dom";

import PageNotFound from "../screens/page-not-found";
import TestCompleted from "../screens/test-completed";
import { DataProvider } from "../hooks/useContext";
import { AssessmentRouting } from "../screens/welcome-screen";
import { ExamScreenV2 } from "../screens/exam-screen";
import AutoSubmitted from "../screens/completed-by-timer";
import { TechnicalTestQuestionScreen } from "../screens/technical-test-question";
import { CustomNavBar } from "../components/navbar/custom-navbar";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <CustomNavBar />,
    children: [
      {
        path: "/",
        element: <PageNotFound />,
        errorElement: <PageNotFound />,
      },

      {
        path: "/:id/*",
        element: <AssessmentRouting />,
        errorElement: <PageNotFound />,
      },
    
      {
        path: ":id/test",
        element:
          <DataProvider>
            <ExamScreenV2 />
          </DataProvider>,
        errorElement: <PageNotFound />,
      },
      {
        path: ":id/test/:questionId",
        element:
          <DataProvider>
            <TechnicalTestQuestionScreen />
          </DataProvider>,
        errorElement: <PageNotFound />,
      },
    ],
    errorElement: <PageNotFound />
  },
  {
    path: ":id/completed",
    element: <TestCompleted />,
    errorElement: <PageNotFound />,
  },
  {
    path: ":id/autocompleted",
    element: <AutoSubmitted />,
    errorElement: <PageNotFound />,
  },
  {
    path: ":id/completed",
    element: <TestCompleted />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/not-found",
    element: <PageNotFound />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default routes;