import React, { useEffect, useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from "@mui/material";
import { ILanguage } from "../../interfaces/test";

interface ILanguageSelector {
  language: ILanguage | undefined;
  onSelect: (lang: ILanguage) => void;
  languages: ILanguage[];
}

const LanguageSelector: React.FC<ILanguageSelector> = ({ languages, language, onSelect }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<ILanguage | undefined>(language);

  useEffect(() => {
    if (language) {
      setSelectedLanguage(language);
    } else if (languages.length > 0) {
      setSelectedLanguage(languages[0]);
      onSelect(languages[0]);
    }
  }, [language, languages, onSelect]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedLang = languages.find(lang => lang._id === event.target.value);
    if (selectedLang) {
      setSelectedLanguage(selectedLang);
      onSelect(selectedLang);
    }
  };

  return (
    <Box marginBottom={2} width={"100%"}>
      <FormControl variant="outlined" sx={{ minWidth: 160 }} size="small">
        <InputLabel style={{alignSelf:"center"}}>Language</InputLabel>
        <Select
          value={selectedLanguage?._id || ""}
          onChange={handleChange}
          label="Language"
        >
          {languages.map((lang) => (
            <MenuItem
              key={lang._id}
              value={lang._id}
            >
              {lang.language}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageSelector;
