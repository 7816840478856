import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { WelcomeScreenV2 } from "./welcome";
import { DynamicInstructions } from "../dynamic-instructions";
import { TestSessionService } from "../../services/test-session";
import { useQuery } from "@tanstack/react-query";

const AssessmentRouting = () => {
  const { getTestSession } = TestSessionService();
  const { id } = useParams(); // Move this inside the component

  const sessionData = useQuery({
    queryKey: ["getTest", id],
    queryFn: () => getTestSession({ _id: id }),
  });

  return (
    <Routes>
      <Route path="/" element={<WelcomeScreenV2 sessionData={sessionData} />} />
      <Route path="/instructions" element={<DynamicInstructions sessionData={sessionData} />} />
    </Routes>
  );
};

export { AssessmentRouting };
