import React from "react";
import { Typography, Box } from "@mui/material";
import "./style.scss";

const TechnicalSubmitted: React.FC = () => 
    <div className="parent-submission-container">
     <span className="title-container"> <h6 className="module-title">{localStorage.getItem("technical_question")}</h6> </span>

    <div className="submission-status">
      <Box className="content" display="flex" flexDirection="column" alignItems="center">
        <div className="icon-container">
          <img
            className="status-icon"
            src="/check.svg"
            alt="check icon"
          />
        </div>
        <Typography variant="h5" className="title">
          Submitted
        </Typography>
        <Typography variant="body2" className="message">
          You have successfully submitted this question.
        </Typography>
        <Typography variant="body2" className="instruction">
          Please select the next question to continue
        </Typography>
      </Box>
    </div>
    </div>;

export { TechnicalSubmitted };
