import useLoader from "../../hooks/useLoader";
import useSnackbar from "../../hooks/useSnackbar";
import axios, { AxiosError } from "axios";

const HttpService = () => {
    const { setIsLoading } = useLoader();
    const { snackbar } = useSnackbar();

    const httpRequest = <T>(
        method: "GET" | "POST" | "PUT" | "DELETE" | "OPTION",
        url: string,
        data = {},
        params = {},
        loading = true
    ) => new Promise<T>((resolve, reject) => {
        loading ? setIsLoading(() => true) : setIsLoading(() => false);
         axios({
            method,
            url: `${process.env.REACT_APP_BASE_URL}/${url}`,
            data,
            params,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("currentUserToken") || ""}`
            }
        })
            .then((response) => {
                setIsLoading(() => false);
                resolve(response.data);
            })
            .catch((err) => {
                const error = err as AxiosError;
                setIsLoading(() => false);
                if (error.response?.status === 401) {
                    localStorage.removeItem("currentUserToken");
                }
                reject(err.response);
            });
    });

    const httpFormRequest = <T>(
        // eslint-disable-next-line
        files: FileList | null,
        fileName: string,
        allowedFiles: string[],
        maxFileSize: number
    ) =>
        new Promise<T>((resolve, reject) => {   
            const file = files && (files || []).length ? files[0] : undefined;

            if (file) {

                const fileExtension = file.name.split(".");
                if (allowedFiles.includes(fileExtension[fileExtension.length - 1].toLowerCase())) {

                    if ((file.size / 1024 / 1024) <= maxFileSize) {

                        setIsLoading(() => true);
                        const formData = new FormData();
                        formData.append("file", file);
                        formData.append("originalname", fileName);
                        axios(
                            {
                                method: "POST",
                                url: `${process.env.REACT_APP_BASE_URL}/upload/`,
                                data: formData,
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${localStorage.getItem("currentUserToken") || ""}`
                                }
                            }
                        )

                            .then((response) => {
                                setIsLoading(() => false);
                                resolve(response.data);
                            })
                            .catch((err) => {
                                const error = err as AxiosError;
                                setIsLoading(() => false);
                                if (error.response?.status === 401) {
                                    localStorage.removeItem("currentUserToken");
                                }
                                reject(err.response);
                            });

                    } else {
                        snackbar(`Size must be less than ${maxFileSize}MB`, "warning");
                    }

                } else {
                    const extensions = allowedFiles.map(extension => `'${extension}' `);
                    snackbar(extensions + " format are allowed", "warning");
                }
            }

        });
    return { httpRequest, httpFormRequest };
};

export default HttpService;