import React, { useEffect, useState } from "react";
import "./style.scss";
import { Typography, Box } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { ISessionDataV2 } from "../../interfaces/test";
import PageNotFound from "../page-not-found";
import { TestSessionService } from "../../services/test-session";
  
const TestCompleted: React.FC = () => {
  const {id} = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [sessionData, setsessionData] = useState<ISessionDataV2 | undefined>(undefined);
  const { getTestSession } = TestSessionService();
  const sessionDataRaw = useQuery({
    queryKey: ["getTestSubmited", id],
    queryFn: () =>
      getTestSession({
        _id: id,
      }),
  });
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const isCompleted = pathSegments.length >= 3 && pathSegments[2].toUpperCase() === "COMPLETED";
  useEffect(() => {
      if (sessionDataRaw.isSuccess && sessionDataRaw.data?.data) {
      setsessionData(sessionDataRaw.data.data);
      setSuccess(true);
      setLoading(false);

    } else if (sessionDataRaw.isError) {
      setLoading(false);
    }
  }, [sessionDataRaw.isSuccess, sessionDataRaw.data?.data, sessionDataRaw.isError]);
  if (loading) return <></>;

  if (!success) return <PageNotFound failure={!success} />;
  return sessionData && sessionData.isSubmitted ? 
  (
    <div className="testcomplete-container" >
         <Box display="flex" className="testcomplete-container-box" flexDirection="column" alignItems="center">
        <img
          className="testcomplete-icon"
          src={"/test-completed.svg"}
          alt="check icon"
        />
        <Typography variant="h4" component="h1" className="testcomplete-header">
        {isCompleted ? (
        <>
          Success! All Tests are Completed.
        </>
      ) : (
        <>
        All Tests are already Completed.
        </>
      )}
        </Typography>
        <Typography variant="body1" className="testcomplete-content">
        {isCompleted ? (
        <>
        You’ve successfully completed all the modules. Your progress is noted, and you’re one step closer to your goal. Keep up the good work!
        </>
      ) : (
        <>
       Your progress is noted, and you’re one step closer to your goal. Keep up the good work!
        </>
      )}
        </Typography>
        <Typography variant="body2" color="textSecondary" className="testcomplete-footer">
          Need assistance? Contact us via email at <span className="text-email">careers@relinns.com </span>
          or call us at +91 7973513060. We’re here to help you with any questions or concerns you may have regarding the test modules.
        </Typography>
      </Box>
      </div>
  ) :
  (<PageNotFound failure={!success} />);
};

export default TestCompleted;