import React from "react";
import { Container, Grid, Card, CardContent, Typography } from "@mui/material";
import "./style.scss";


const InstructionScreen = () => {
  const instructions = [  
    "Start the Test Stay Calm Take a deep breath and approach each question with confidence Good Luck!We wish you the best of luck as you demonstrate your knowledge and skills." ,
    "Remember, you are well-prepared and capable of succeeding!\n" 
  ];
  
  return(
  
    <Container maxWidth="lg" className="instruction-container">
      <Grid>
        <Typography variant="h6" fontWeight={600} gutterBottom className="header" mb={2}>
        Reminder
        </Typography>
        <Card className="instruction-card">
          <CardContent>
            <Typography variant="h4" gutterBottom className="instruction-header">
              <strong>Please select a module to begin the assessment</strong>
            </Typography>
            <Typography variant="body1" className="instruction-text" sx={{textAlign :"center"}}>
              <ul className="instruction-list">
                {instructions.map((instruction, index) => (
                  <li key={index} className="instruction-list">
                    {instruction}
                  </li>
                ))}
              </ul>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
};

export { InstructionScreen };
